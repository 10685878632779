import React from 'react'
import { Row, Col, Card, CardBody, CardTitle, Form } from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import EditTripForm from './EditTripForm';

const EditTrip = () => {

  return (
    <React.Fragment>
      <EditTripForm />
    </React.Fragment>
  )
}

export default EditTrip