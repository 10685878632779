import React, { useEffect, useState } from 'react';
import { Row, Col, Card, CardBody, CardTitle, Label, Input, Form, FormFeedback } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { pushCustomer, setCustomerData, updateCustomer } from 'store/auth/user_admin_data/actions';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { getAllEnquiry, patchEnquiryRequestEdit } from 'helpers/fakebackend_helper';
import Alert from 'components/alert/Alert';

const EditEquiryForm = ({ type }) => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const navigate = useNavigate();

    const [showError, setShowError] = useState(false)
    const [message, setMessage] = useState("some Things Went's Wrong")

    let { customerData } = useSelector((state) => state.CustomerReducers);
    const customPackage = customerData.find((customer) => customer.id == id);



    const fetchData = async () => {
        try {
            let res = await getAllEnquiry();

            console.log(res)

            dispatch(setCustomerData(res))
            if (customPackage) {
                validation.setFieldValue("name", customPackage?.name || '');
                validation.setFieldValue("email", customPackage?.email || '');
                validation.setFieldValue("phone", customPackage?.phone || '');
                validation.setFieldValue("designation", customPackage?.designation || '');
                validation.setFieldValue("travelMonths", customPackage?.travelMonths || '');
                validation.setFieldValue("destination", customPackage?.destination || '');
                validation.setFieldValue("company", customPackage?.company || '');
                validation.setFieldValue("additionalInfo", customPackage?.additionalInfo || '');
                validation.setFieldValue("employees", customPackage?.employees || '');
            }

        } catch (error) {
            console.log("something wrongs")

        }
    }

    useEffect(() => {

        fetchData();


    }

        , [id])


    // const [customPackageEdit, setCustomPackageEdit] = useState(
    //  });

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: '',
            email: '',
            phone: '',
            designation: '',
            travelMonths: '',
            destination: '',
            company: '',
            additionalInfo: '',
            employees: ''
        }
        ,
        validationSchema: Yup.object({
            name: Yup.string().required('Please Enter User Name'),
            email: Yup.string().required('Please Enter Email'),
            phone: Yup.number().required('Please Enter Phone Number'),
            designation: Yup.string().required('Please Enter Designation'),
            travelMonths: Yup.string().required('Please Enter Travel Months'),
            destination: Yup.string().required('Please Enter Destination'),
            company: Yup.string().required('Please Enter Departure Date'),
            additionalInfo: Yup.string().required('Please Enter Additional Info'),
            employees: Yup.number().required('Please Enter This Field'),
        }),
        onSubmit: async (values) => {
            // Handle form submission logic here
            console.log(values);
            if (type === 'Edit') {
                let res = await patchEnquiryRequestEdit(values, id)
                console.log(res)
                if (res.id) {

                    navigate('/customerEnquiry')
                }
                else {
                    showError(true)

                }
                // Update logic
            } else {
                // Create logic
            }
        },
    });

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs link="customerDetails" maintitle="Carvaan" title="Customers Request" breadcrumbItem={`${type} Request`} />
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <CardTitle className="h4">{`${type}`} Request</CardTitle>
                                    <Form onSubmit={validation.handleSubmit}>
                                        <div data-repeater-list="group-a">
                                            <div data-repeater-item className="row w-100">


                                                <div className="mb-3 col-lg-3">
                                                    <Label className="form-label" htmlFor="name">
                                                        User Name
                                                    </Label>
                                                    <Input
                                                        type="text"
                                                        id="name"
                                                        required
                                                        className="form-control"
                                                        placeholder="Enter name"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.name}
                                                        invalid={validation.touched.name && !!validation.errors.name}
                                                    />
                                                    <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                                                </div>
                                                <div className="mb-3 col-lg-3">
                                                    <Label className="form-label" htmlFor="email">
                                                        User Email
                                                    </Label>
                                                    <Input
                                                        type="email"
                                                        id="email"
                                                        required
                                                        className="form-control"
                                                        placeholder="Enter Email"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.email}
                                                        invalid={validation.touched.email && !!validation.errors.email}
                                                    />
                                                    <FormFeedback type="invalid">{validation.errors.emai}</FormFeedback>
                                                </div>
                                                <div className="mb-3 col-lg-3">
                                                    <Label className="form-label" htmlFor="phone">
                                                        Phone Number
                                                    </Label>
                                                    <Input
                                                        type="number"
                                                        id="phone"
                                                        required
                                                        className="form-control"
                                                        placeholder="Enter Phone Number"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.phone}
                                                        invalid={validation.touched.phone && !!validation.errors.phone}
                                                    />
                                                    <FormFeedback type="invalid">{validation.errors.phone}</FormFeedback>
                                                </div>
                                                <div className="mb-3 col-lg-3">
                                                    <Label className="form-label" htmlFor="destination">
                                                        Destination
                                                    </Label>
                                                    <Input
                                                        type="text"
                                                        id="destination"
                                                        className="form-control"
                                                        required
                                                        placeholder="Enter Destination"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.destination}
                                                        invalid={validation.touched.destination && !!validation.errors.destination}
                                                    />
                                                    <FormFeedback type="invalid">{validation.errors.destination}</FormFeedback>
                                                </div>
                                                <div className="mb-3 col-lg-3">
                                                    <Label className="form-label" htmlFor="travelMonths">
                                                    Travel Months
                                                    </Label>
                                                    <Input
                                                        type="text"
                                                        id="travelMonths"
                                                        className="form-control"
                                                        required
                                                        placeholder="Enter TravelMonths"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.travelMonths}
                                                        invalid={validation.touched.travelMonths && !!validation.errors.travelMonths}
                                                    />
                                                    <FormFeedback type="invalid">{validation.errors.travelMonths}</FormFeedback>
                                                </div>
                                                <div className="mb-3 col-lg-3">
                                                    <Label className="form-label" htmlFor="designation">
                                                    Designation
                                                    </Label>
                                                    <Input
                                                        type="text"
                                                        id="designation"
                                                        className="form-control"
                                                        required
                                                        placeholder="Enter Designation"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.designation}
                                                        invalid={validation.touched.designation && !!validation.errors.designation}
                                                    />
                                                    <FormFeedback type="invalid">{validation.errors.designation}</FormFeedback>
                                                </div>
                                                <div className="mb-3 col-lg-3">
                                                    <Label className="form-label" htmlFor="company">
                                                        Company
                                                    </Label>
                                                    <Input
                                                        type="text"
                                                        id="company"
                                                        className="form-control"
                                                        placeholder="Enter Duration "
                                                        required
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.company}
                                                        invalid={validation.touched.company && !!validation.errors.company}
                                                    />
                                                    <FormFeedback type="invalid">{validation.errors.company}</FormFeedback>
                                                </div>
                                                <div className="mb-3 col-lg-3">
                                                    <Label className="form-label" htmlFor="employees">
                                                        Number of Employees                                                    </Label>
                                                    <Input
                                                        type="number"
                                                        id="employees"
                                                        className="form-control"
                                                        placeholder="Enter Child Seats "
                                                        name='employees'
                                                        required
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.employees}
                                                        invalid={validation.touched.employees && !!validation.errors.employees}
                                                    />
                                                    <FormFeedback type="invalid">{validation.errors.employees}</FormFeedback>
                                                </div>
                                                <div className="mb-3 col-lg-12">
                                                    <Label className="form-label" htmlFor="additionalInfo">
                                                        Additional Info
                                                    </Label>
                                                    <Input
                                                        type="textarea"
                                                        id="additionalInfo"

                                                        rows={4}
                                                        className="form-control"
                                                        placeholder="Enter Message "
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.additionalInfo}
                                                    />
                                                </div>
                                            </div>


                                        </div>






                                        <button type="submit" className="mt-1 btn btn-success">
                                            {type === 'Edit' ? 'Update Request' : 'Create Customer'}
                                        </button>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
            {
                true && <div className=" position-fixed  " style={{ top: "50px", right: "0", zIndex: "9999" }}>
                    <Alert message={message} type="error" />
                </div>
            }
        </React.Fragment>
    );
};

export default EditEquiryForm;
