import axios from "axios"
import { post, del, get, put, patch } from "./api_helper"
import * as url from "./url_helper"

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("user")
  if (user) return JSON.parse(user)
  return null
}

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null
}

// Register Method
const postFakeRegister = (data) => post(url.POST_FAKE_REGISTER, data)

// Edit Admin
// const patchAdminEdit = (newData,id) => patch(url.PATCH_ADMIN_EDIT, newData)
 const patchAdminEdit = (newData, id) => {
  const url = `admins/${id}`; // Construct the URL with the id parameter
  return patch(url, newData);
};

// Delete Admin
// const deleteAdmin = (data) => del(url.DELETE_ADMIN, data)
const deleteAdmin = (id) => {
  const url = `admins/${id}`; // Construct the URL with the id parameter
  return del(url);
};

// Login Method
const postFakeLogin = data => post(url.POST_FAKE_LOGIN, data)


// get All admins
const getAllAdmins = data => get(url.GET_ALL_ADMIN, data)


// postForgetPwd
const postFakeForgetPwd = data => post(url.POST_FAKE_PASSWORD_FORGET, data)

// Edit profile
const postJwtProfile = data => post(url.POST_EDIT_JWT_PROFILE, data)

const postFakeProfile = data => post(url.POST_EDIT_PROFILE, data)



// Trips page request

const getAllMeals = data => get(url.GET_ALL_MEALS)
const getAllAgeRange = data => get(url.GET_ALL_AGE_RANGES)
const getAllTransportationTypes = data => get(url.GET_ALL_TRANSPORTATION)
const get_All_Travel_Agents = data => get(url.GET_ALL_TRAVEL_AGENTS)

const uploadTripImage = data => post(url.POST_TRIP_IMAGE,data,{headers:{ "Content-Type": "multipart/form-data","Accept":"*/*" }})
const uploadTripImages = data => post(url.POST_TRIP_IMAGE_MULTIPLE,data)
const getAllPoints = data => get(url.GET_ALL_POINTS)
const getAllThemes = data => get(url.GET_ALL_THEMES)
const createPackage = data => post(url.CREATE_PACKAGE,data)
const getPackage = data => get(url.GET_PACKAGE)


const createExistPackage = data => post(url.CREATE_EXIST_PACKAGE,data)
const getExistPackage = data => get(url.GET_EXIST_PACKAGE,data)
const patchExistPackageEdit = (newData, id) => {
  const url = `existing_packages/${id}`; // Construct the URL with the id parameter
  return patch(url, newData);
};



// Travel Agents

const createAgentPackage = data => post(url.CREATE_AGENT,data)
const getAgents = data => get(url.GET_AGENTS,data)
const patchAgentsEdit = (newData, id) => {
  const url = `travel_agents/${id}`; // Construct the URL with the id parameter
  return patch(url, newData);
};

const deleteAgent = (id) => {
  const url = `travel_agents/${id}`; // Construct the URL with the id parameter
  return del(url);
};


const getOneAgents = (id) => get(`/travel_agents/${id}`)



const patchPackageEdit = (newData, id) => {
  const url = `packages/${id}`; // Construct the URL with the id parameter
  return patch(url, newData);
};
const deleteTrip = (id) => {
  const url = `packages/${id}`; // Construct the URL with the id parameter
  return del(url);
};
const getPackageDatabyId = (id) => {
  const url = `packages/${id}`; // Construct the URL with the id parameter
  return get(url);
};



const deleteExistTrip = (id) => {
  const url = `existing_packages/${id}`; // Construct the URL with the id parameter
  return del(url);
};

// Custom Packages
const getAllCustomPackages = data => get(url.GET_CUSTOM_PACKAGE)

const getAllEnquiry = data => get(url.GET_ENQUIRY)

const getAllTour = data => get(url.GET_TOUR)

const getAllUser = data => get(url.GET_USER)

const deleteEnquiry = (id) => {
  const url = `enquiries/${id}`; // Construct the URL with the id parameter
  return del(url);
};

const deleteTour = (id) => {
  const url = `tour/${id}`; // Construct the URL with the id parameter
  return del(url);
};

const patchEnquiryRequestEdit = (newData, id) => {
  const url = `enquiries/${id}`;
  return put(url, newData);
};

const patchTourRequestEdit = (newData, id) => {
  const url = `tour/${id}`;
  return put(url, newData);
};

const patchCustomPackagesRequestEdit = (newData, id) => {
  const url = `custom_packages/${id}`; // Construct the URL with the id parameter
  return patch(url, newData);
};
const deleteCustomPackagesRequest = (id) => {
  const url = `custom_packages/${id}`; // Construct the URL with the id parameter
  return del(url);
};




// Contact us
const getAllContactUs = data => get(url.GET_CONTACT_US)

const deleteContactUs = (id) => {
  const url = `contact_requests/${id}`; // Construct the URL with the id parameter
  return del(url);
};

// Register Method
const postJwtRegister = (url, data) => {
  return axios
    .post(url, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data
      throw response.data
    })
    .catch(err => {
      let message
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found"
            break
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team"
            break
          case 401:
            message = "Invalid credentials"
            break
          default:
            message = err[1]
            break
        }
      }
      throw message
    })
}

// Login Method
const postJwtLogin = data => post(url.POST_FAKE_JWT_LOGIN, data)

// postForgetPwd
const postJwtForgetPwd = data => post(url.POST_FAKE_JWT_PASSWORD_FORGET, data)

// get Events
export const getEvents = () => get(url.GET_EVENTS)

// add Events
export const addNewEvent = event => post(url.ADD_NEW_EVENT, event)

// update Event
export const updateEvent = event => put(url.UPDATE_EVENT, event)

// delete Event
export const deleteEvent = event =>
  del(url.DELETE_EVENT, { headers: { event } })

// get Categories
export const getCategories = () => get(url.GET_CATEGORIES)

export {
  getLoggedInUser,
  isUserAuthenticated,
  postFakeRegister,
  postFakeLogin,
  postFakeProfile,
  postFakeForgetPwd,
  postJwtRegister,
  postJwtLogin,
  postJwtForgetPwd,
  postJwtProfile,
  getAllAdmins,
  patchAdminEdit,
  deleteAdmin,
  getAllMeals,
  getAllAgeRange,
  getAllTransportationTypes,
  get_All_Travel_Agents,
  uploadTripImage,
  getAllPoints,
  getAllThemes,
  createPackage,
  uploadTripImages,
  getPackage,
  patchPackageEdit,
  deleteTrip,
  getAllCustomPackages,
  patchCustomPackagesRequestEdit,
  deleteCustomPackagesRequest,
  createExistPackage,
  getAllContactUs,
  deleteContactUs,
  getExistPackage,
  deleteExistTrip,
  patchExistPackageEdit,
  createAgentPackage,
  getAgents,
  patchAgentsEdit,
  deleteAgent,
  getOneAgents,
  getAllEnquiry,
  deleteEnquiry,
  patchEnquiryRequestEdit,
  getAllTour,
  deleteTour,
  patchTourRequestEdit,
  getAllUser,
  getPackageDatabyId
}
