import React, { useState, useEffect } from 'react';
import {
  Form,
  Input,
  Label,
  FormGroup,
  Button,
  Row,
  Col,
  Container,
  Badge,
} from 'reactstrap';
import { createPackage, get_All_Travel_Agents, uploadTripImages } from 'helpers/fakebackend_helper';
import { SomethingAlertFalse, SomethingAlertTrue } from 'store/components/actions';
import { storeAgents } from 'store/auth/user_admin_data/actions';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import "react-image-crop/dist/ReactCrop.css";
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.snow.css';
import ReactQuill, { Quill } from 'react-quill';
import CropDialog from './ImageCropDemo/ImageCropperExample';
import { boolean } from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { bool, number } from 'prop-types';

const CreateTripForm = () => {
  const Font = Quill.import('formats/font');
  Font.whitelist = ['sans-serif', 'serif', 'monospace'];
  Quill.register(Font, true);

  // Toolbar and editor configuration
  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ size: [] }],
      [{ header: '1' }, { header: '2' }, { font: [] }],
      [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
      ['link', 'clean'],
    ],
  };

  const formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'video',
  ];

  const [isCropDialogOpen, setIsCropDialogOpen] = useState(false);
  const [itinerary, setItinerary] = useState(""); // For the current input
  const [itineraryArray, setItineraryArray] = useState([]);
  const [croppedImagePreviews, setCroppedImagePreviews] = useState([]);
  const [imageToCrop, setImageToCrop] = useState(null);
  const [selectedBanners, setSelectedBanners] = useState([]);
  const [message, setMessage] = useState("Something went's wrong")
  const navigate = useNavigate();
  const handleImageUpload = (e) => {
    const files = e.target.files;
    if (files && files.length) {
      Array.from(files).forEach((file) => {
        const reader = new FileReader();
        reader.onload = () => {
          setImageToCrop(reader.result); // Set the image for cropping
          setIsCropDialogOpen(true); // Open the crop dialog
        };
        reader.readAsDataURL(file); // Read the file as a data URL
      });
    }
  };
  const handleCropComplete = async (croppedBlob) => {
    const croppedFile = new File([croppedBlob], `cropped-image-${Date.now()}.jpeg`, {
      type: "image/jpeg",
    });

    const reader = new FileReader();
    reader.onloadend = () => {
      setCroppedImagePreviews((prev) => [...prev, reader.result]); // Add new preview to the array
      handleFileChange([croppedFile]); // Pass the file for uploading
    };
    reader.readAsDataURL(croppedFile);

    setIsCropDialogOpen(false);
  };
  const handleFileChange = async (imageFiles) => {
    setLoader(true);
    const formData = new FormData();

    imageFiles.forEach((file) => {
      formData.append("files", file);
    });

    try {
      const res = await uploadTripImages(formData);

      if (res) {
        const uploadedImageUrls = res.map((item) => item.image_url);
        setSelectedBanners((prev) => [...prev, ...uploadedImageUrls]);
      } else {
        console.error("Error: Invalid response format", res?.data);
      }

      setLoader(false);
    } catch (error) {
      console.error("Error uploading image:", error);
      setLoader(false);
    }
  };

  const deleteCroppedImage = (indexToRemove) => {
    setCroppedImagePreviews((prevPreviews) =>
      prevPreviews.filter((_, index) => index !== indexToRemove)
    );
  };

  const handleAddItinerary = () => {
    if (itinerary.trim()) {
      setItineraryArray([...itineraryArray, itinerary]);
      setItinerary(""); // Clear the input after adding
    }
  };

  // Function to handle deleting an itinerary
  const handleDeleteItinerary = (index) => {
    setItineraryArray(itineraryArray.filter((_, i) => i !== index));
  };
  const fetchOptions = async () => {
    try {
      ;
      const agents = await get_All_Travel_Agents()
      console.log("============", agents)
      setTravelAgents(agents)
      dispatch(storeAgents(agents))


    } catch (error) {
      setLoader(false);
      dispatch(SomethingAlertTrue());
      setTimeout(() => {
        dispatch(SomethingAlertFalse());
        setMessage("Something went wrong3");
      }, 2000);
    }
  }
  useEffect(() => {
    fetchOptions()
  }, [])
  const [loader, setLoader] = useState(false);
  const [formData, setFormData] = useState({
    title: '',
    pick_up_location: '',
    drop_location: '',
    tour_type: '',
    starting_point_id: '',
    ending_point_id: '',
    seats_left: '',
    duration_days: number,
    start_date: '',
    price: number,
    actual_price: number,
    number_of_meals: number,
    meal_type_id: '',
    transportation_type_id: '',
    flights_inclusive: boolean,
    trip_captain_required: boolean,
    is_handpicked: boolean,
    is_trending: boolean,
    travel_agent_id: number,
    description: '',
    is_active: boolean
  });

  const [travelAgents, setTravelAgents] = useState([])
  const [travelAgentsId, setTravelAgentsId] = useState()
  console.log("travelAgentsId=========", travelAgentsId)
  const [admin, setAdmin] = useState(null);


  useEffect(() => {
    const authUser = JSON.parse(localStorage.getItem('authUser'));

    if (authUser && authUser.admin && authUser.admin.id) {
      setAdmin(authUser.admin.id);
      console.log("Admin ID set to state:", authUser.admin.id);
    } else {
      console.error("Admin ID not found in localStorage!");
    }
  }, []);
  const dispatch = useDispatch();
  const [lists, setLists] = useState({
    accomodation_type_id: [],
    age_range_ids: [],
    theme_ids: [],
    middle_point_ids: [],
    inclusion: [],
    exclusives: [],
    facilities: [],
    itineraries: [],
    packing_guide: [],
    day_wise_itenary: []
  });

  const handleChange = (e) => {
    const { name, value, type } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'number' ? parseFloat(value) || 0 : value,
    }));
  };

  // const handleAddToList = (key, value) => {
  //   if (value.trim() !== '') {
  //     setLists((prevLists) => ({
  //       ...prevLists,
  //       [key]: [...prevLists[key], value],
  //     }));
  //     setFormData((prevData) => ({
  //       ...prevData,
  //       [key]: '',
  //     }));
  //   }
  // };
  const handleAddToList = (key, value) => {
    if (typeof value === 'string' && value.trim() !== '') {
      setLists((prevLists) => ({
        ...prevLists,
        [key]: [...(prevLists[key] || []), value], // Ensure `prevLists[key]` is an array
      }));
      setFormData((prevData) => ({
        ...prevData,
        [key]: '', // Reset the form data for the key
      }));
    } else {
      console.warn(`Invalid value provided for key "${key}":`, value);
    }
  };

  const handleDeleteFromList = (key, index) => {
    setLists((prevLists) => ({
      ...prevLists,
      [key]: prevLists[key].filter((_, i) => i !== index),
    }));
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    const combinedData = {
      ...formData,
      images: selectedBanners,
      accomodation_type_id: lists.accomodation_type_id,
      age_range_ids: lists.age_range_ids,
      theme_ids: lists.theme_ids,
      middle_point_ids: lists.middle_point_ids,
      inclusion: lists.inclusion,
      exclusives: lists.exclusives,
      facilities: lists.facilities,
      itineraries: lists.itineraries,
      packing_guide: lists.packing_guide,
      day_wise_itenary: itineraryArray,
      travel_agent_id: travelAgentsId,
      created_by: admin,
    };
    let res = await createPackage(combinedData)
    if (res.id) {
      navigate('/tripDetails')
    }
  };
  const handleDescriptionChange = (value) => {
    setFormData((prevData) => ({
      ...prevData,
      description: value,
    }));
  };
  const handleTravelAgentChange = (e) => {
    const selectedId = parseInt(e.target.value, 10); // Ensure the ID is a number
    setTravelAgentsId(selectedId);
    setFormData((prevData) => ({
      ...prevData,
      travel_agent_id: selectedId, // Update formData as well
    }));
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs maintitle="Carvaan" title="Group Packages" breadcrumbItem="Create Package" />

          <Row className="justify-content-center mt-1 p-4">
            {/* <Col md={10}> */}
            <h4 className="mb-4">Create Trip Form</h4>
            <Form onSubmit={handleSubmit}>

              <div>
                <input className="mb-4" type="file" multiple onChange={handleImageUpload} />
                <div className="mt-3 d-flex">
                  {croppedImagePreviews.map((preview, index) => (
                    <div
                      key={index}
                      style={{
                        position: "relative",
                        display: "inline-block",
                        marginRight: "10px",
                      }}
                    >
                      <img
                        src={preview}
                        alt="Cropped"
                        style={{
                          width: "100px",
                          height: "100px",
                          objectFit: "cover",
                        }}
                      />
                      <Col className='ms-5 pe-2 col-3'>
                        <i onClick={() => deleteCroppedImage(index)} className='fas fa-trash-alt text-danger' role="button"></i>
                      </Col>
                      {/* ))} */}
                    </div>
                  ))}
                </div>

                <CropDialog
                  open={isCropDialogOpen}
                  image={imageToCrop}
                  onClose={() => setIsCropDialogOpen(false)}
                  onCrop={handleCropComplete}
                />
              </div>
              <div className="d-flex gap-3">
                <div className="mb-3 col-lg-4">
                  <FormGroup>
                    <Label className="form-label" htmlFor="travel_agent_id">
                      Company Name*
                    </Label>
                    <Input
                      type="select"
                      id="travel_agent_id"
                      className="form-control"
                      name="travel_agent_id"
                      required
                      onChange={handleTravelAgentChange}
                    >
                      <option value="">Select a company</option>
                      {travelAgents?.map((option, index) => (
                        <option key={index} value={option.id}>
                          {option.name}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                </div>
                <div className="mb-3 col-lg-4">
                  <FormGroup>
                    <Label for="title">Trip Title</Label>
                    <Input
                      type="text"
                      id="title"
                      name="title"
                      value={formData.title}
                      onChange={handleChange}
                    />
                  </FormGroup>
                </div>
                <div className="mb-3 col-lg-4">
                  <FormGroup>
                    <Label for="pick_up_location">Pickup Location (optional)</Label>
                    <Input
                      type="text"
                      id="pick_up_location"
                      name="pick_up_location"
                      value={formData.pick_up_location}
                      onChange={handleChange}
                    />
                  </FormGroup>
                </div>
              </div>

              <div className='d-flex gap-3'>
                <div className="mb-3 col-lg-4">
                  <FormGroup>
                    <Label for="drop_location">Drop Location (optional)</Label>
                    <Input
                      type="text"
                      id="drop_location"
                      name="drop_location"
                      value={formData.drop_location}
                      onChange={handleChange}
                    />
                  </FormGroup>
                </div>
                <div className="mb-3 col-lg-4">
                  <FormGroup>
                    <Label for="tour_type">Domestic or International</Label>
                    <Input
                      type="select"
                      id="tour_type"
                      name="tour_type"
                      value={formData.tour_type}
                      onChange={handleChange}
                    >
                      <option value="" disabled>Select Options</option>
                      <option value="domestic">Domestic</option>
                      <option value="international">International</option>
                    </Input>
                  </FormGroup>
                </div>
                <div className="mb-3 col-lg-4">
                  <FormGroup>
                    <Label for="starting_point_id">Pickup City*</Label>
                    <Input
                      type="text"
                      id="starting_point_id"
                      name="starting_point_id"
                      value={formData.starting_point_id}
                      onChange={handleChange}
                    />
                  </FormGroup>
                </div>

              </div>


              <div className='d-flex gap-3'>
                <div className="mb-3 col-lg-4">
                  <FormGroup >
                    <Label for="ending_point_id">Destination City*</Label>
                    <Input
                      type="text"
                      id="ending_point_id"
                      name="ending_point_id"
                      value={formData.ending_point_id}
                      onChange={handleChange}
                    />
                  </FormGroup>
                </div>
                <div className="mb-3 col-lg-4">
                  <FormGroup>
                    <Label for="seats_left">Seats Left</Label>
                    <Input
                      type="number"
                      id="seats_left"
                      name="seats_left"
                      value={formData.seats_left}
                      onChange={handleChange}
                    />
                  </FormGroup>
                </div>
                <div className="mb-3 col-lg-4">
                  <FormGroup>
                    <Label for="duration_days">Duration (in days)</Label>
                    <div className='d-flex'>
                      <div>
                        <Input
                          type="number"
                          id="duration_days"
                          name="duration_days"
                          value={formData.duration_days}
                          onChange={(e) => {
                            const value = parseInt(e.target.value, 10); // Ensure valid number
                            setFormData((prevData) => ({
                              ...prevData,
                              duration_days: value,
                            }));
                          }}
                        />
                      </div>
                      <div>
                        {formData.duration_days > 0 && (
                          <p className="mt-2 text-muted">
                            {formData.duration_days} Day{formData.duration_days > 1 ? "s" : ""} and{" "}
                            {formData.duration_days - 1} Night
                          </p>
                        )}
                      </div>
                    </div>


                  </FormGroup>
                </div>
              </div>
              <div className='d-flex gap-3'>
                <div className="mb-3 col-lg-4">
                  <FormGroup >
                    <Label for="start_date">Start Date</Label>
                    <Input
                      type="date"
                      id="start_date"
                      name="start_date"
                      value={formData.start_date}
                      onChange={handleChange}
                    />
                  </FormGroup>
                </div>
                <div className="mb-3 col-lg-4">
                  <FormGroup>
                    <Label for="price">Discount Price Per Person*</Label>
                    <Input
                      type="number"
                      id="price"
                      name="price"
                      value={formData.price}
                      onChange={handleChange}
                    />
                  </FormGroup>
                </div>
                <div className="mb-3 col-lg-4">
                  <FormGroup>
                    <Label for="actual_price">Actual Price Per Person*</Label>
                    <Input
                      type="number"
                      id="actual_price"
                      name="actual_price"
                      value={formData.actual_price}
                      onChange={handleChange}
                    />
                  </FormGroup>
                </div>
              </div>
              <div className='d-flex gap-3'>
                <div className="mb-3 col-lg-4">
                  <FormGroup>
                    <Label className="form-label" htmlFor="trip_captain_required">
                      Trip Captain*
                    </Label>
                    <Input
                      type="select"
                      className="form-control"
                      id="trip_captain_required"
                      name="trip_captain_required"
                      value={formData.trip_captain_required}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          trip_captain_required: e.target.value === "true",
                        })
                      }
                    >
                      <option value="" disabled>Select</option>
                      <option value={true}>yes</option>
                      <option value={false}>no</option>
                    </Input>
                  </FormGroup>
                </div>

                <div className="mb-3 col-lg-4">
                  <FormGroup >
                    <Label className="form-label" htmlFor="transportation_type_id">
                      Transportation*
                    </Label>
                    <Input
                      type="select"
                      className="form-control"
                      id="transportation_type_id"
                      name="transportation_type_id"
                      value={formData.transportation_type_id}
                      onChange={handleChange}
                    >
                      <option value="" disabled>Select Options</option>
                      <option value="volvo">Volvo</option>
                      <option value="traveller">Traveller</option>
                      <option value="train">Train</option>
                      <option value="flight">Flight</option>
                      <option value="private_car">Private Car</option>
                      <option value="Ferry">Ferry</option>
                    </Input>
                  </FormGroup>
                </div>

                <div className="mb-3 col-lg-4">


                  <FormGroup>
                    <Label className="form-label" htmlFor="accomodation_type_id">
                      Accomodation*
                    </Label>
                    <div className='d-flex align-items-center'>
                      <select
                        id="accomodation_type_id"
                        name="accomodation_type_id"
                        onChange={handleChange}
                        value={formData.accomodation_type_id}
                        className="form-control"
                      >
                        <option value="" disabled selected>Select an option</option>
                        <option value="camp">Camp</option>
                        <option value="hotel">Hotel</option>
                        <option value="dorm">Dorm</option>
                        <option value="cruise">Cruise</option>
                        <option value="resort">Resort</option>
                      </select>
                      <button
                        onClick={() => handleAddToList('accomodation_type_id', formData.accomodation_type_id)}
                        type='button'
                        className='btn btn-success mx-1'
                      >Add</button>
                    </div>
                    <div className='d-flex flex-column'>
                      {lists.accomodation_type_id.length > 0 && (
                        <div className="">
                          {lists.accomodation_type_id.map((data, i) => (
                            <div className='d-flex justify-content-between mx-3' key={`edit-${i}`}>
                              <h6 className='my-2 w-100 d-flex align-items-start justify-content-between'>
                                {data}
                                <Badge className='mx-2 bg-transparent'>
                                  <i
                                    onClick={() => handleDeleteFromList('accomodation_type_id', i)}
                                    type="button"
                                    className="fas fa-window-close fs-5 text-danger"
                                  />
                                </Badge>
                              </h6>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </FormGroup>
                </div>

              </div>




              <div className='d-flex gap-3'>
                <div className="mb-3 col-lg-4">
                  <FormGroup>
                    <Label for="number_of_meals">Number of Meals</Label>
                    <Input
                      type="number"
                      id="number_of_meals"
                      name="number_of_meals"
                      value={formData.number_of_meals}
                      onChange={handleChange}
                    />
                  </FormGroup>
                </div>
                <div className="mb-3 col-lg-4">
                  <FormGroup >
                    <Label className="form-label" htmlFor="is_handpicked">
                      Mark this trip as "Handpicked" ? (optional)*
                    </Label>
                    <Input
                      type="select"
                      className="form-control"
                      id="is_handpicked"
                      name="is_handpicked"
                      value={formData.is_handpicked}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          is_handpicked: e.target.value === "true",
                        })
                      }
                    >
                      <option value="" disabled>Select Options</option>
                      <option value={false}>no</option>
                      <option value={true}>yes</option>
                    </Input>
                  </FormGroup>
                </div>
                <div className="mb-3 col-lg-4">
                  <FormGroup >
                    <Label className="form-label" htmlFor="is_trending">
                      Mark this Package as Trending  ? (optional)
                    </Label>
                    <Input
                      type="select"
                      className="form-control"
                      id="is_trending"
                      name="is_trending"
                      value={formData.is_trending}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          is_trending: e.target.value === "true",
                        })
                      }

                    >
                      <option value="" disabled>Select Options</option>
                      <option value={false}>no</option>
                      <option value={true}>yes</option>
                    </Input>
                  </FormGroup>
                </div>
              </div>
              <div className="mb-3 col-lg-3 mt-3 py-3 d-flex flex-column justify-content-start">
                <label className="form-label" htmlFor="is_active">
                  Is Active
                </label>
                <FormGroup switch className="d-flex align-items-center" style={{ height: '-webkit-fill-available' }}>
                  <Input
                    type="switch"
                    name="is_active"
                    role="switch"
                    id="is_active"
                    value={formData.is_active}
                    onChange={handleChange}
                  />

                  <Label
                    className="mb-0 ms-3"
                    // check={isActive}
                    style={{
                      color: 'green' || 'red',
                      display: 'inline-block',
                    }}
                  >
                    {'Active' || 'In Active'}
                  </Label>
                </FormGroup>
              </div>
              <style>
                {`
                            .ql-editor strong {
                               font-weight: bold !important;
                                               }
                            .ql-editor b {
                               font-weight: bold !important;
                                               }
                          `}
              </style>
              <div className="mb-3 col-lg-12">
                <Label className="form-label" htmlFor="description">
                  Description*
                </Label>
                <ReactQuill
                  value={formData.description}
                  onChange={(value) => handleDescriptionChange(value)}
                  placeholder="Enter Description"
                  required
                  modules={modules}
                  formats={formats}
                  style={{ height: '150px', resize: 'none' }}
                />
              </div>
              <div className='d-flex gap-3 mt-5'>
                <div className="mb-3 col-lg-4">
                  <FormGroup>
                    <Label className="form-label" htmlFor="age_range_ids">
                      Age Range*
                    </Label>
                    <div className='d-flex align-items-center'>
                      <select
                        id="age_range_ids"
                        name="age_range_ids"
                        onChange={handleChange}
                        value={formData.age_range_ids}
                        className="form-control"
                      >
                        <option value="" disabled selected>Select an option</option>
                        <option value="18-25">18-25</option>
                        <option value="26-35">26-35</option>
                        <option value="36-45">36-45</option>
                        <option value="46-55">46-55</option>
                        <option value="Above-55">Above-55</option>
                      </select>
                      <button
                        onClick={() => handleAddToList('age_range_ids', formData.age_range_ids)}
                        type='button'
                        className='btn btn-success mx-1'
                      >Add</button>
                    </div>
                    <div className='d-flex flex-column'>
                      {lists.age_range_ids.length > 0 && (
                        <div className="">
                          {lists.age_range_ids.map((data, i) => (
                            <div className='d-flex justify-content-between mx-3' key={`edit-${i}`}>
                              <h6 className='my-2 w-100 d-flex align-items-start justify-content-between'>
                                {data}
                                <Badge className='mx-2 bg-transparent'>
                                  <i
                                    onClick={() => handleDeleteFromList('age_range_ids', i)}
                                    type="button"
                                    className="fas fa-window-close fs-5 text-danger"
                                  />
                                </Badge>
                              </h6>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </FormGroup>
                </div>

                <div className="mb-3 col-lg-4">
                  <FormGroup>
                    <Label className="form-label" htmlFor="theme_ids">
                      Themes that best describe your Group Trip(optional)
                    </Label>
                    <div className='d-flex align-items-center'>
                      <select
                        id="theme_ids"
                        name="theme_ids"
                        onChange={handleChange}
                        value={formData.theme_ids}
                        className="form-control"
                      >
                        <option value="" disabled selected>Select an option</option>
                        <option value="Couple Friendly">Couple Friendly</option>
                        <option value="Women Only">Women Only</option>
                        <option value="Dating Trip">Dating Trip</option>
                        <option value="Senior Citizen Friendly">Senior Citizen Friendly</option>
                        <option value="Disability Friendly">Disability Friendly</option>
                        <option value="Religious ">Religious </option>
                        <option value="Party">Party</option>
                        <option value="Mountain">Mountain</option>
                        <option value="Beach">Beach</option>
                        <option value="Forest">Forest</option>
                        <option value="River">River</option>
                        <option value="Desert">Desert</option>
                        <option value="Bachelor's ">Bachelor's </option>
                        <option value="Solo">Solo</option>
                        <option value="Corporate Trip">Corporate Trip</option>
                        <option value="Adventure">Adventure </option>
                        <option value="Hiking">Hiking</option>
                        <option value="Honeymoon">Honeymoon </option>
                        <option value="Offbeat">Offbeat</option>
                        <option value="Wildlife">Wildlife </option>
                      </select>
                      <button
                        onClick={() => handleAddToList('theme_ids', formData.theme_ids)}
                        type='button'
                        className='btn btn-success mx-1'
                      >Add</button>
                    </div>
                    <div className='d-flex flex-column'>
                      {lists.theme_ids.length > 0 && (
                        <div className="">
                          {lists.theme_ids.map((data, i) => (
                            <div className='d-flex justify-content-between mx-3' key={`edit-${i}`}>
                              <h6 className='my-2 w-100 d-flex align-items-start justify-content-between'>
                                {data}
                                <Badge className='mx-2 bg-transparent'>
                                  <i
                                    onClick={() => handleDeleteFromList('theme_ids', i)}
                                    type="button"
                                    className="fas fa-window-close fs-5 text-danger"
                                  />
                                </Badge>
                              </h6>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </FormGroup>
                </div>
                <div className="mb-3 col-lg-4">
                  <FormGroup >
                    <Label className="form-label" htmlFor="meal_type_id">
                      Food Options*
                    </Label>
                    <Input
                      type="select"
                      className="form-control"
                      id="meal_type_id"
                      name="meal_type_id"
                      value={formData.meal_type_id}
                      onChange={handleChange}
                    >
                      <option value="" disabled>Select Food Options</option>
                      <option value="veg">Veg</option>
                      <option value="non-veg">Non-Veg</option>
                      <option value="jain">Jain</option>
                    </Input>
                  </FormGroup>
                </div>
              </div>

              <div className='d-flex gap-3'>

                <div className="mb-3 col-lg-4">
                  <FormGroup>
                    <Label for="packing_guide">Packing Guide</Label>
                    <div className='d-flex'>
                      <Input
                        type="text"
                        id="packing_guide"
                        name="packing_guide"
                        value={formData.packing_guide}
                        onChange={handleChange}
                      />
                      <Button
                        color="success"
                        onClick={() => handleAddToList('packing_guide', formData.packing_guide)}
                        className="ms-2"
                      >
                        Add
                      </Button>
                    </div>

                    <div className="mt-2">
                      {lists.packing_guide.map((item, index) => (
                        <div key={index} className="d-flex justify-content-between align-items-center">
                          <span>{item}</span>
                          <Badge className='mx-2 bg-transparent'>
                            <i
                              onClick={() => handleDeleteFromList('packing_guide', index)}
                              type="button"
                              className="fas fa-window-close fs-5 text-danger"
                            />
                          </Badge>
                        </div>
                      ))}
                    </div>
                  </FormGroup>
                </div>

                <div className="mb-3 col-lg-4">
                  <FormGroup>
                    <Label for="middle_point_ids">Middle Points</Label>
                    <div className='d-flex'>
                      <Input
                        type="text"
                        id="middle_point_ids"
                        name="middle_point_ids"
                        value={formData.middle_point_ids}
                        onChange={handleChange}
                      />
                      <Button
                        color="success"
                        onClick={() => handleAddToList('middle_point_ids', formData.middle_point_ids)}
                        className="ms-2"
                      >
                        Add
                      </Button>
                    </div>

                    <div className="mt-2">
                      {lists.middle_point_ids.map((item, index) => (
                        <div key={index} className="d-flex justify-content-between align-items-center">
                          <span>{item}</span>
                          <Badge className='mx-2 bg-transparent'>
                            <i
                              onClick={() => handleDeleteFromList('middle_point_ids', index)}
                              type="button"
                              className="fas fa-window-close fs-5 text-danger"
                            />
                          </Badge>
                        </div>
                      ))}
                    </div>
                  </FormGroup>
                </div>

                <div className="mb-3 col-lg-4">
                  <FormGroup>
                    <Label for="inclusion">Inclusion</Label>
                    <div className='d-flex'>
                      <Input
                        type="text"
                        id="inclusion"
                        name="inclusion"
                        value={formData.inclusion}
                        onChange={handleChange}
                      />
                      <Button
                        color="success"
                        onClick={() => handleAddToList('inclusion', formData.inclusion)}
                        className="ms-2"
                      >
                        Add
                      </Button>
                    </div>

                    <div className="mt-2">
                      {lists.inclusion.map((item, index) => (
                        <div key={index} className="d-flex justify-content-between align-items-center">
                          <span>{item}</span>
                          <Badge className='mx-2 bg-transparent'>
                            <i
                              onClick={() => handleDeleteFromList('inclusion', index)}
                              type="button"
                              className="fas fa-window-close fs-5 text-danger"
                            />
                          </Badge>
                        </div>
                      ))}
                    </div>
                  </FormGroup>
                </div>


              </div>

              <div className='d-flex gap-3'>
                <div className="mb-3 col-lg-4">
                  <FormGroup>
                    <Label for="exclusives">Exclusion</Label>
                    <div className='d-flex'>
                      <Input
                        type="text"
                        id="exclusives"
                        name="exclusives"
                        value={formData.exclusives}
                        onChange={handleChange}
                      />
                      <Button
                        color="success"
                        onClick={() => handleAddToList('exclusives', formData.exclusives)}
                        className="ms-2"
                      >
                        Add
                      </Button>
                    </div>

                    <div className="mt-2">
                      {lists.exclusives.map((item, index) => (
                        <div key={index} className="d-flex justify-content-between align-items-center">
                          <span>{item}</span>
                          <Badge className='mx-2 bg-transparent'>
                            <i
                              onClick={() => handleDeleteFromList('exclusives', index)}
                              type="button"
                              className="fas fa-window-close fs-5 text-danger"
                            />
                          </Badge>
                        </div>
                      ))}
                    </div>
                  </FormGroup>
                </div>
                <div className="mb-3 col-lg-4">
                  <FormGroup>
                    <Label for="facilities">Highlight</Label>
                    <div className='d-flex'>
                      <Input
                        type="text"
                        id="facilities"
                        name="facilities"
                        value={formData.facilities}
                        onChange={handleChange}
                      />
                      <Button
                        color="success"
                        onClick={() => handleAddToList('facilities', formData.facilities)}
                        className="ms-2"
                      >
                        Add
                      </Button>
                    </div>

                    <div className="mt-2">
                      {lists.facilities.map((item, index) => (
                        <div key={index} className="d-flex justify-content-between align-items-center">
                          <span>{item}</span>
                          <Badge className='mx-2 bg-transparent'>
                            <i
                              onClick={() => handleDeleteFromList('facilities', index)}
                              type="button"
                              className="fas fa-window-close fs-5 text-danger"
                            />
                          </Badge>
                        </div>
                      ))}
                    </div>
                  </FormGroup>
                </div>
                <div className="mb-3 col-lg-4">
                  <FormGroup>
                    <Label className="form-label" htmlFor="flights_inclusive">
                      Flight Ticket Required*
                    </Label>
                    <Input
                      type="select"
                      className="form-control"
                      id="flights_inclusive"
                      name="flights_inclusive"
                      value={formData.flights_inclusive}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          flights_inclusive: e.target.value === "true",
                        })
                      }
                    >
                      <option value="" disabled>
                        Select Options
                      </option>
                      <option value="false">No</option>
                      <option value="true">Yes</option>
                    </Input>
                  </FormGroup>
                </div>

              </div>
              <div className="mb-3 col-lg-12">
                <label className="form-label" htmlFor="day_wise_itenary">
                  Itinerary
                </label>
                <div className="mb-2">
                  <b>Day Wise</b>
                </div>
                <div className="inline-block">
                  <div className="d-flex justify-content-between">
                    <ReactQuill
                      value={itinerary}
                      onChange={(value) => setItinerary(value)}
                      placeholder="Enter itinerary"
                      className="w-100"
                      modules={{
                        toolbar: [
                          ["bold", "italic", "underline"],
                          [{ list: "ordered" }, { list: "bullet" }],
                        ],
                      }}
                      formats={["bold", "italic", "underline", "list", "bullet"]}
                    />
                  </div>
                  <div className="d-flex justify-content-center">
                    <button
                      onClick={handleAddItinerary}
                      style={{ width: "200px" }}
                      type="button"
                      className="btn my-5 btn-success py-2 mx-1"
                    >
                      Add More
                    </button>
                  </div>

                </div>

                <div className="d-flex flex-column">
                  {itineraryArray.map((data, i) => {
                    const hasColon = data.includes(":");
                    const displayText = hasColon ? data.split(":")[1].trim() : data;
                    console.log("day=========", displayText)
                    return (
                      <div className="d-flex justify-content-between mx-3" key={i}>
                        <div
                          className="fw-bold d-flex align-items-center"
                          style={{ minWidth: "200px" }}
                        >
                          <b>Day {i + 1}</b>
                        </div>
                        <h6 className="my-2 w-100 d-flex align-items-start justify-content-between">
                          <div
                            className="w-100"
                            dangerouslySetInnerHTML={{ __html: displayText }}
                          />
                          <Badge className="mx-2 bg-transparent">
                            <i
                              onClick={() => handleDeleteItinerary(i)}
                              role="button"
                              className="fas fa-window-close fs-5 text-danger"
                            ></i>
                          </Badge>
                        </h6>
                      </div>
                    );
                  })}
                </div>


              </div>
              <button type='submit' className="mt-1 btn btn-success mb-5">
                Create Trip
              </button>
            </Form>
          </Row>
        </div>
      </div>
    </React.Fragment>

  );
};

export default CreateTripForm;
