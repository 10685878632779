import axios from "axios"
// import accessToken from "./jwt-token-access/accessToken"

//pass new generated access token here
// const token = accessToken

//apply base url for axios

const API_URL = process.env.REACT_APP_API_LIVE_URL

const axiosApi = axios.create({ 
  baseURL: API_URL,
}) 


// axiosApi.defaults.headers.common["Authorization"] = token;

axiosApi.interceptors.request.use((config) => {
  let authUser = JSON.parse(localStorage.getItem("authUser"));
  // config.headers.common["source"] = `IS_ADMIN_RELATED`;
  config.headers["source"] = `IS_ADMIN_RELATED`;
  config.headers.common["ngrok-skip-browser-warning"] = true;

  // console.log({authUser});
  if(authUser){
    // config.headers.common["Authorization"] = `Bearer ${authUser.accessToken}`;
    config.headers.cookies = `token=${authUser.refreshToken}`;
    config.headers["Authorization"] = `Bearer ${authUser.accessToken}`;


  }
  return config;
}, (error) => {
  return Promise.reject(error);
});


// axiosApi.interceptors.response.use(
//   response => response,
//   error => Promise.reject(error)
// )

// Add a response interceptor
axiosApi.interceptors.response.use(function (response) {

  return response;
}, async (error)=> {


// if(error.response.status===401){
// let refreshToken=JSON.parse(localStorage.getItem('userData')).refreshToken
// let payload={
//   refresh_token:refreshToken
// }

// let apiResponse=await axios.post("/admins/refresh",payload)
// const existingDataString = localStorage.getItem('userData');
// let userData = JSON.parse(existingDataString);
// userData.accessToken = apiResponse.data.access_token;
// localStorage.setItem('userData', JSON.stringify(userData));

// error.config.headers["Authorization"]=`bearer ${apiResponse.data.access_token}`
// return axios(error.config);
// }
// else{
  return Promise.reject(error)
// }
});



export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then(response => response.data)
}

export async function post(url, data, config = {}) {
  return axiosApi
    // .post(url, { ...data }, { ...config })
    .post(url, data , { ...config })
    .then(response => response.data)
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
}
export async function patch(url, data, config = {}) {
  return axiosApi
    .patch(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
}
