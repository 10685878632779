import React, { useEffect } from 'react';
import {
    Row, Col, Card, CardBody, CardTitle, Container, Form,
    CardImg
} from 'reactstrap';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getAllUser, } from 'helpers/fakebackend_helper';
import { setUserData } from 'store/auth/user_admin_data/actions';
import { format } from "date-fns";

const ViewTourRequest = () => {
    const { id } = useParams();
    const dispatch = useDispatch();




    const fetchOptions = async () => {
        try {
            const AllUser = await getAllUser();

            dispatch(setUserData(AllUser));

        } catch (error) {
            console.log(error.response)
        }
    }
    useEffect(() => {
        fetchOptions()
    }, [id])

    const { customerData } = useSelector((state) => state.CustomerReducers);



    const customPackage = customerData.find((cPackage) => cPackage.id == id);


    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs link="/user" maintitle="Carvaan" title="Tour" breadcrumbItem={`View Request`} />

                    <Row>
                        <Col className="col-12">
                            <Card >
                                <CardBody>
                                    <CardTitle className="h4">{`View`} Request</CardTitle>

                                    <div data-repeater-list="group-a">
                                        <div data-repeater-item className="row w-100">

                                            <div className="mb-3 col-lg-3">
                                                <label className="form-label" htmlFor="user_name">
                                                    Name
                                                </label>
                                                <Card body className="border">
                                                    <CardTitle className="h4">{customPackage?.name}</CardTitle>
                                                </Card>
                                            </div>

                                            <div className="mb-3 col-lg-3">
                                                <label className=" form-label" htmlFor="to">
                                                    Phone Number
                                                </label>
                                                <Card body className="border">
                                                    <CardTitle className="h4">{customPackage?.phone_number}</CardTitle>
                                                </Card>

                                            </div>

                                            <div className="mb-3 col-lg-3">
                                                <label className=" form-label" htmlFor="country">
                                                    City
                                                </label>
                                                <Card body className="border">
                                                    <CardTitle className="h4">{(customPackage?.city_name)}</CardTitle>
                                                </Card>
                                            </div>
                                            <div className="mb-3 col-lg-3">
                                                <label className=" form-label" htmlFor="country">
                                                    DOB
                                                </label>
                                                <Card body className="border">
                                                    <CardTitle className="h4">{(customPackage?.date_of_birth)}</CardTitle>
                                                </Card>
                                            </div>
                                            <div className="mb-3 col-lg-3">
                                                <label className=" form-label" htmlFor="country">
                                                    Email
                                                </label>
                                                <Card body className="border">
                                                    <CardTitle className="h4">{(customPackage?.email)}</CardTitle>
                                                </Card>
                                            </div>
                                            <div className="mb-3 col-lg-3">
                                                <label className=" form-label" htmlFor="country">
                                                    Gender
                                                </label>
                                                <Card body className="border">
                                                    <CardTitle className="h4">{(customPackage?.gender)}</CardTitle>
                                                </Card>
                                            </div>
                                            <div className="mb-3 col-lg-3">
                                                <label className=" form-label" htmlFor="country">
                                                Address
                                                </label>
                                                <Card body className="border">
                                                    <CardTitle className="h4">{(customPackage?.physical_address)}</CardTitle>
                                                </Card>
                                            </div>
                                            <div className="mb-3 col-lg-3">
                                                <label className=" form-label" htmlFor="country">
                                                Whatsapp Number
                                                </label>
                                                <Card body className="border">
                                                    <CardTitle className="h4">{(customPackage?.whatsapp_number)}</CardTitle>
                                                </Card>
                                            </div>
                                            <div className="mb-3 col-lg-3">
                                                <label className=" form-label" htmlFor="country">
                                                Coins
                                                </label>
                                                <Card body className="border">
                                                    <CardTitle className="h4">{(customPackage?.coins)}</CardTitle>
                                                </Card>
                                            </div>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </div>
            </div>
        </React.Fragment>
    );
};

export default ViewTourRequest;
