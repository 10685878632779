import React, { useEffect, useRef } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

const CropDialog = ({ open, image, onClose, onCrop }) => {
  const cropperRef = useRef(null);

  useEffect(() => {
    if (cropperRef.current?.cropper) {
      // Set fixed crop box dimensions once the image loads
      cropperRef.current.cropper.setCropBoxData({
        width: 858, // Fixed width in px
        height: 500, // Fixed height in px
        left: (cropperRef.current.cropper.getContainerData().width - 858) / 2, // Center horizontally
        top: (cropperRef.current.cropper.getContainerData().height - 500) / 2, // Center vertically
      });
    }
  }, [image]); // Run whenever the image changes

  // const handleCrop = () => {
  //   if (cropperRef.current?.cropper) {
  //     const croppedData = cropperRef.current.cropper.getCroppedCanvas().toDataURL();
  //     onCrop(croppedData); // Pass cropped image back to the parent
  //   }
  // };
  const handleCrop = () => {
    if (cropperRef.current?.cropper) {
      cropperRef.current.cropper
        .getCroppedCanvas()
        .toBlob((blob) => {
          if (blob) onCrop(blob); // Pass the Blob back to the parent
        }, "image/jpeg");
    }
  };

  if (!open) return null;

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 1000,
      }}
    >
      <div
        style={{
          background: "white",
          padding: 20,
          borderRadius: 10,
          width: "80%",
          maxWidth: "900px",
        }}
      >
        <h2>Crop Image</h2>
        <Cropper
          ref={cropperRef}
          style={{ height: 600, width: "100%" }} // Adjust container size
          zoomTo={0.5}
          initialAspectRatio={858 / 500} // Maintain aspect ratio
          preview=".img-preview"
          src={image}
          viewMode={1}
          background={false}
          responsive={true}
          autoCropArea={1}
          checkOrientation={false}
          guides={true}
          cropBoxResizable={true} // Disable resizing of crop box
          cropBoxMovable={true} // Disable moving the crop box
        />
        <div style={{ marginTop: 20, textAlign: "right" }}>
          <button onClick={onClose} style={{ marginRight: 10 }}>
            Cancel
          </button>
          <button onClick={handleCrop}>Crop</button>
        </div>
      </div>
    </div>
  );
};

export default CropDialog;
