import React from 'react'
// import { Row, Col, Card, CardBody, CardTitle,Form } from "reactstrap";
//Import Breadcrumb
// import Breadcrumbs from "../../components/Common/Breadcrumb";
import EditTourForm from './EditTour';

const TourForm = () => {

  return (
    <React.Fragment>
      <EditTourForm type="Edit" />
    </React.Fragment>
  )
}

export default TourForm