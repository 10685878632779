import React from 'react'
// import { Row, Col, Card, CardBody, CardTitle,Form } from "reactstrap";
//Import Breadcrumb
// import Breadcrumbs from "../../components/Common/Breadcrumb";
import EditEquiryForm from './EnquiryEdit';

const EquiryForm = () => {

  return (
    <React.Fragment>
      <EditEquiryForm type="Edit" />
    </React.Fragment>
  )
}

export default EquiryForm