// CreateCustomerForm.js
import React, { useEffect } from 'react';
import {
    Row, Col, Card, CardBody, CardTitle, Container, Form,
    CardImg
} from 'reactstrap';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getAllEnquiry, } from 'helpers/fakebackend_helper';
import { setEnquiryData } from 'store/auth/user_admin_data/actions';
import { format } from "date-fns";

const ViewEnquiryRequest = () => {
    const { id } = useParams();
    const dispatch = useDispatch();




    const fetchOptions = async () => {
        try {
            const AllEnquiry = await getAllEnquiry();

            dispatch(setEnquiryData(AllEnquiry));

        } catch (error) {
            console.log(error.response)
        }
    }
    useEffect(() => {
        fetchOptions()
    }, [id])

    const { customerData } = useSelector((state) => state.CustomerReducers);



    const customPackage = customerData.find((cPackage) => cPackage.id == id);


    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs link="/customerEnquiry" maintitle="Carvaan" title="Custom Enquiry" breadcrumbItem={`View Request`} />

                    <Row>
                        <Col className="col-12">
                            <Card >
                                <CardBody>
                                    <CardTitle className="h4">{`View`} Request</CardTitle>

                                    <div data-repeater-list="group-a">
                                        <div data-repeater-item className="row w-100">

                                            <div className="mb-3 col-lg-3">
                                                <label className="form-label" htmlFor="user_name">
                                                    User Name
                                                </label>
                                                <Card body className="border">
                                                    <CardTitle className="h4">{customPackage?.name}</CardTitle>
                                                </Card>
                                            </div>

                                            <div className="mb-3 col-lg-3">
                                                <label className="  form-label" htmlFor="from">
                                                    Company Name
                                                </label>
                                                <Card body className="border">
                                                    <CardTitle className="h4">{customPackage?.company}</CardTitle>
                                                </Card>

                                            </div>

                                            <div className="mb-3 col-lg-3">
                                                <label className=" form-label" htmlFor="to">
                                                    Phone Number
                                                </label>
                                                <Card body className="border">
                                                    <CardTitle className="h4">{customPackage?.phone}</CardTitle>
                                                </Card>

                                            </div>

                                            <div className="mb-3 col-lg-3">
                                                <label className=" form-label" htmlFor="destination">
                                                    Destination
                                                </label>
                                                <Card body className="border">
                                                    <CardTitle className="h4">{(customPackage?.destination)}</CardTitle>
                                                </Card>
                                            </div>

                                            <div className="mb-3 col-lg-3">
                                                <label className="form-label" htmlFor="employees">
                                                    Number of Employees
                                                </label>
                                                <Card body className="border">
                                                    <CardTitle className="h4">{customPackage?.employees}</CardTitle>
                                                </Card>

                                            </div>

                                            <div className="mb-3 col-lg-3">
                                                <label className="form-label" htmlFor="email">
                                                    Email
                                                </label>
                                                <Card body className="border">
                                                    <CardTitle className="h4">{customPackage?.email}</CardTitle>
                                                </Card>
                                            </div>

                                            <div className="mb-3 col-lg-3">
                                                <label className="form-label" htmlFor="designation">
                                                    Designation
                                                </label>
                                                <Card body className="border">
                                                    <CardTitle className="h4">{customPackage?.designation}</CardTitle>
                                                </Card>

                                            </div>
                                            
                                            <div className="mb-3 col-lg-3">
                                                <label className=" form-label" htmlFor="travelMonths">
                                                Travel Month
                                                </label>
                                                <Card body className="border">
                                                    <CardTitle className="h4">{customPackage?.travelMonths}</CardTitle>
                                                </Card>

                                            </div>
                                            
                                            <div className="mb-3 col-lg-12">
                                                <label className=" form-label" htmlFor="additionalInfo">
                                                Additional Info
                                                </label>{" "}
                                                <Card body className="border">
                                                    <CardTitle className="h4">{customPackage?.additionalInfo}</CardTitle>
                                                </Card>

                                            </div>
                                            {/* 
                                            <div className="mb-3 col-lg-3">
                                                <label className="form-label" htmlFor="hotel_ratings">
                                                    Hotel Ratings
                                                </label>
                                                <Card body className="border">
                                                    {customPackage?.hotel_ratings.map((rating, index) => (
                                                        <div key={index} className='d-flex align-items-center'>
                                                            <CardTitle className="h4 mb-0">{index + 1}</CardTitle>
                                                            <CardBody className='fs-6'>
                                                                {rating}
                                                            </CardBody>      </div>
                                                    ))}
                                                </Card>
                                            </div> */}
                                            {/* 
                                            <div className="mb-3 col-lg-3">
                                                <label className="form-label" htmlFor="meal_types">
                                                    Meal Types
                                                </label>
                                                <Card body className="border">
                                                    {customPackage?.meal_types.map((meal, index) => (
                                                        <div key={index} className='d-flex align-items-center' >
                                                            <CardTitle className="h4 ms-2 ">{index + 1}</CardTitle>
                                                            <CardTitle className="h4 ms-2 ">{meal}</CardTitle>
                                                        </div>
                                                    ))}
                                                </Card>
                                            </div> */}

                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </div>
            </div>
        </React.Fragment>
    );
};

export default ViewEnquiryRequest;
